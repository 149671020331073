
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Chart from '../Charts/Chart.vue';
import { ChartGenerator } from '@/models/Charts/abstract/chartGenerator';
import {
  BendProductivityFiguresData,
  BendProductivityFiguresName,
} from '@/models/Charts/chartsData';
import { abbreviateNumber } from '@/utils/number';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import i18n from '@/i18n';
import { weightUnit } from '@/utils/measurement';
import ValueTrend from '@/components/common/ValueTrend.vue';
import { timeFilterToUnitKeyMap } from '@/components/bend/timeFilterToUnitKeyMap';

/**
 * Table with a heatmap chart, current and previous values, and the percentage
 * difference since the previous value.
 */
@Component({
  methods: { abbreviateNumber },
  components: {
    Chart,
    ValueTrend,
  },
})
export default class BendProductivityFigures extends Vue {
  @Prop({ required: true })
  private data!: BendProductivityFiguresData[] | null;

  @Prop({ required: true })
  private widgetDefinition!: WidgetDefinition;

  @Prop({ required: true, default: false, type: Boolean })
  private loading!: boolean;

  /**
   * Chart generator to generate the heatmap charts.
   *
   * Not used to fetch the data. It comes from `data` prop instead.
   */
  @Prop({ required: true })
  private dataRetriever!: ChartGenerator<BendProductivityFiguresData[]>;

  private forceRerenderKey = 1;

  private mounted() {
    // HACK: Forces the re-rendering of the component so the table doesn't
    // overflow the widget card when the screen orientation changes on a tablet.
    window.addEventListener('resize', this.forceRerender)
  }

  private getFigureUnits(figureEntry: BendProductivityFiguresData): string {
    switch (figureEntry.name) {
      case BendProductivityFiguresName.BendCount:
      case BendProductivityFiguresName.ToolChangesCount:
        return `/${i18n.tc(timeFilterToUnitKeyMap[this.widgetDefinition.timeFilterEnumValue!], 1)}`;
      case BendProductivityFiguresName.ProducedWeight:
      case BendProductivityFiguresName.WeightHandledByOperator:
      default:
        return weightUnit() + `/${i18n.tc(
          timeFilterToUnitKeyMap[this.widgetDefinition.timeFilterEnumValue!],
          1,
        )}`;
    }
  }

  private get currentPeriodColumnHeader(): string {
    return this.getPeriodColumnHeader('current');
  }

  private get previousPeriodColumnHeader(): string {
    return this.getPeriodColumnHeader('previous');
  }

  private getPeriodColumnHeader(periodPrefix: 'current' | 'previous'): string {
    const periodKey = FilterTimeSpanEnum[this.widgetDefinition.timeFilter as FilterTimeSpanEnum];
    return i18n.t(`${periodPrefix}_time_period.${periodKey}`).toString();
  }

  private forceRerender() {
    this.forceRerenderKey++;
  }

  private destroy() {
    window.removeEventListener('resize', this.forceRerender)
  }

  private get coalescedData(): BendProductivityFiguresData[] {
    return this.data ?? [];
  }
}
